<template>
  <ul class="navigation-bar">
    <li
      v-for="item in adminNavigation"
      :key="item.name"
      class="navigation-bar__item"
    >
      <router-link :to="{ path: `/admin/${item.path}`}">{{item.name}}</router-link>
    </li>
  </ul>
</template>
<script>
export default {
  created() {
    this.$store.dispatch('getEditionsData');
  },
  data() {
    return {
      adminNavigation: {
        companies: {
          name: 'Firmy',
          path: 'firmy',
        },
        editions: {
          name: 'Edycje',
          path: 'edycje',
        },
        users: {
          name: 'Użytkownicy',
          path: 'uzytkownicy',
        },
        news: {
          name: 'Wiadomości',
          path: 'wiadomosci',
        },
        packages: {
          name: 'Pakiety',
          path: 'pakiety',
        },
        services: {
          name: 'Usługi',
          path: 'uslugi',
        },
      },
    };
  },
};
</script>
